<template>
  <div class="coupe-de-france-du-potager">
    <div class="w-100 text-center">
      <img :alt="$t('logoPath')" class="max-w-xs" :src="logoPath">
    </div>

    <div v-if="!this.$store.state.account.user" class="w-100 d-flex justify-content-center mt-1">
      <b-card bg-variant="secondary-light" class="mt-1 max-w-md text-center">
        <b-card-text>
          <strong class="text-danger mb-1">{{ $t("homePage.warning") }}</strong>
          {{ $t("homePage.notConnected") }}
          <br>
          <br>
          <i>
            {{ $t("homePage.newPlatform") }}
          </i>
          <br>
          <br>
          <strong>{{ $t("homePage.useReferenceMail") }}</strong>
          <br>

        </b-card-text>
        <b-button
            :variant="$store.state.appConfig.layout.skin == 'dark' ? 'secondary' : 'primary'"
            class="mx-1 mb-1"
            :class="$store.state.appConfig.layout.skin == 'dark' ? 'text-primary' : 'text-white'"
            @click="$router.push({path: '/login'})">
          {{ $t("homePage.signIn") }}
        </b-button>
        <b-button
            :variant="$store.state.appConfig.layout.skin == 'dark' ? 'secondary' : 'primary'"
            class="mx-1 mb-1"
            :class="$store.state.appConfig.layout.skin == 'dark' ? 'text-white' : 'text-primary'"
            @click="$router.push({path: '/register?creation_code=cdf_potager'})">
          {{ $t("homePage.signUp") }}
        </b-button>
      </b-card>
    </div>

    <div v-if="this.$store.state.account.user">
      <div class="d-flex justify-content-center mb-3">
        <b-button
            variant="secondary"
            class="m-50"
            @click="toCurrentQuiz">
          <div v-if="userCategoryName">
            <div class="font-weight-bolder p-50 text-primary">{{ $t('homePage.accessQuiz') }}</div>
            <div class="font-italic text-primary">{{ userCategoryName }}</div>
          </div>
          <div v-else>
            <div class="font-weight-bolder p-50 text-primary">{{ $t('homePage.noQuiz') }}</div>
            <div class="font-italic text-primary">{{ $t('homePage.selectCategory') }}</div>
          </div>
        </b-button>
      </div>

      <b-card class="coupe-de-france-du-potager-intro text-center max-w-xl">
        <b-card-body class="max-w-lg">
          <CFPIntro/>
        </b-card-body>
      </b-card>

      <jaya-divider color="transparent"/>

      <b-card :title="$t('help.needHelp')" class="max-w-xl">
        <CFPHelp class="max-w-lg"/>
      </b-card>
    </div>
  </div>
</template>

<script>
import CFPIntro from '@/components/cfp/CFPIntro.vue'
import {BButton, BCard, BCardBody, BCardText} from "bootstrap-vue";
import JayaDivider from "@/components/global/JayaDivider.vue";
import CFPHelp from "@/components/cfp/CFPHelp";
import logoEn from '@/assets/images/cfp/logo_cfp_en.png';
import logoFr from '@/assets/images/cfp/logo_coupe.png';
import logoEs from '@/assets/images/cfp/logo_cfp_es.png';

export default {
  name: 'coupe-de-france-du-potager',
  components: {
    CFPIntro,
    BButton,
    BCard,
    BCardBody,
    BCardText,
    JayaDivider,
    CFPHelp,
  },
  data() {
    return {
      logos: { en: logoEn, fr: logoFr, es: logoEs }
    };
  },
  computed: {
    userCategoryName() {
      if (this.$store.state.cfp.quizTypeName && this.$store.state.account.user) {
        return this.$store.state.cfp.quizTypeName.find(el => el.type === this.$store.state.account.user.category).name[this.$store.state.account.user.language]
      } else {
        return ''
      }
    },
    logoPath(){
      const currentLang = this.$i18n.locale; // Assuming you have access to $i18n object
      return this.logos[currentLang] || this.logos.en;
    }
  },
  methods: {
    toCurrentQuiz() {
      if (this.userCategoryName) {
        this.$router.push({name: 'coupe-de-france-du-potager-evaluation'})
      } else {
        this.$router.push({name: 'user-account'})
      }
    },
  }
}
</script>

<template>
  <b-row class="mr-0 ml-0">
    <div class="d-flex align-items-start p-1 overflow-hidden m-0">
      <feather-icon
          icon="MessageSquareIcon"
          size="40"
          class="mx-1"
          :class="$store.state.appConfig.layout.skin == 'dark' ? 'text-secondary' : 'text-primary'"
      />
      <p class="p-0 m-0 overflow-hidden">
        {{ $t('help.contactInfo') }}
        <br>
        <a class="underline font-weight-bold" href="mailto:coupedefrancedupotager@landestini.org"
           :class="$store.state.appConfig.layout.skin == 'dark' ? 'text-secondary' : 'text-primary'">
          {{ $t('help.contactEmail').replace('@', '&#64;').replace('.', '&#46;') }}
        </a>
      </p>
    </div>
  </b-row>
</template>


<script>
import {BRow} from "bootstrap-vue";

export default {
  name: 'CFPHelp',
  components: {
    BRow,
  },
}
</script>
